import React, { useState } from "react"
import { Product } from "../../types/product"
import {
  buyProduct,
  capitalize,
  downloadDemo,
  downloadProduct,
  price,
} from "../../utils"
import Badge from "../Badge/Badge"
import * as styles from "./ProductDescription.module.css"
import { FaFileDownload } from "@react-icons/all-files/fa/FaFileDownload"
import Button from "../Button/Button"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { useForm } from "react-hook-form"
import { newsletterSubscribe } from "../../utils/newsletter"

type NewsletterForm = {
  email: string
}

const newsletterFormName = "newsletter"

const ProductDescription: React.FC<{
  product: Product
  className?: string
}> = ({ product, className }) => {
  const [newsletterCtaVisible, setNewsletterCtaVisible] = useState<boolean>(
    false
  )
  const { register, handleSubmit, errors } = useForm<NewsletterForm>()
  const {
    file: {
      childImageSharp: { fluid: newsletterIcon },
    },
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "newsletter-icon.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const onDownloadFreeClick = () => {
    setNewsletterCtaVisible(true)
  }

  const onNewsletterFormSubmit = async (data: NewsletterForm) => {
    try {
      await newsletterSubscribe(data.email, newsletterFormName, {
        title: "Thank you for subscription!",
        message: "Download will start shortly",
      })
      try {
        setNewsletterCtaVisible(false)
        downloadProduct(product)
      } catch (error) {}
    } catch (error) {}
  }

  const onSkipNewsletterProduct = async () => {
    setNewsletterCtaVisible(false)
    downloadProduct(product)
  }

  return (
    <div className={`${styles.productDescription} ${className || ""}`}>
      <div className={styles.title}>{product.name}</div>
      <div className={styles.compatibilityBadges}>
        {product.compatible.map((item, idx) => (
          <Badge key={idx} icon={item} className={styles.compatibilityBadge}>
            {capitalize(item)}
          </Badge>
        ))}
      </div>
      {newsletterCtaVisible ? (
        <form
          name={newsletterFormName}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          method="post"
          noValidate={true}
          onSubmit={handleSubmit(onNewsletterFormSubmit)}
        >
          <input type="hidden" name="form-name" value={newsletterFormName} />
          <div className={styles.newsletterCtaHeader}>
            <div className={styles.newsletterCtaHeaderText}>
              Subscribe to our newsletter and get new freebies and product
              updates in your inbox. No spam, we promise!
            </div>
            <div className={styles.newsletterIcon}>
              <Image fluid={newsletterIcon} className="hero-img" />
            </div>
          </div>
          <div className={styles.newsletterInputWrap}>
            <input
              className={`${styles.newsletterInput} mm-form-input`}
              name="email"
              type="email"
              ref={register({
                required: "Please type your email",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Looks like your email is invalid",
                },
              })}
              placeholder="Your email"
            />
            {errors && errors.email ? (
              <div className="mm-form-error-message">
                {errors.email.message}
              </div>
            ) : null}
          </div>
          <div className={styles.newsletterButtons}>
            <Button
              onClick={() => onSkipNewsletterProduct()}
              color="light"
              size="default"
              fill="clear"
            >
              Skip
            </Button>
            <Button color="primary" size="default" type="submit">
              Subscribe and download
            </Button>
          </div>
        </form>
      ) : (
        <>
          <div className={styles.features}>
            <ul>
              {product.features.map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </ul>
          </div>
          <div className={styles.buttons}>
            <Button
              size="large"
              onClick={
                product.price
                  ? () => buyProduct(product)
                  : () => onDownloadFreeClick()
              }
            >
              {product.price
                ? `Buy for ${price(product.price)}`
                : `Download for free`}
            </Button>
            {product.price && product.demo ? (
              <button
                className={styles.demoButton}
                onClick={() => downloadDemo(product.demo!)}
              >
                <FaFileDownload /> Download demo
              </button>
            ) : null}
          </div>
        </>
      )}
    </div>
  )
}

export default ProductDescription
