import React from "react"
import Layout from "../components/Layout/Layout"
import ProductDescription from "../components/ProductDescription/ProductDescription"
import { Product } from "../types/product"
import styles from "./product.module.css"
import SEO from "../components/SEO/SEO"
import CategoryProducts from "../components/CategoryProducts/CategoryProducts"
import { graphql } from "gatsby"
import productsJSON from "../data/products.json"
import Image from "gatsby-image"

const ProductTemplate: React.FC<{
  pageContext: {
    productId: Product["id"]
    previewImagesDir: string
    imagesDir: string
  }
  data
}> = ({ pageContext: { productId }, data }) => {
  const product = productsJSON.products.find(item => item.id === productId)!
  const {
    previewAllFile: { edges: previewFiles },
    productAllFile: { edges: productFiles },
  } = data
  const previewImages = previewFiles.map(
    ({
      node: {
        childImageSharp: { fluid },
      },
    }) => fluid
  )
  const headerDesktopImage = productFiles.find(
    ({ node: { name } }) => name === "desktop-header"
  ).node.childImageSharp.fluid
  const headerMobileImage = productFiles.find(
    ({ node: { name } }) => name === "mobile-header"
  ).node.childImageSharp.fluid
  const usageDesktopImage = productFiles.find(
    ({ node: { name } }) => name === "desktop-usage"
  ).node.childImageSharp.fluid
  const usageMobileImage = productFiles.find(
    ({ node: { name } }) => name === "mobile-usage"
  ).node.childImageSharp.fluid
  return (
    <>
      <SEO title={product.name} />
      <Layout>
        <div className={styles.product}>
          <div className={styles.images}>
            <div className={styles.heroImg}>
              <Image
                className={styles.heroImg__mobile}
                fluid={headerMobileImage}
              />
              <Image
                className={styles.heroImg__desktop}
                fluid={headerDesktopImage}
              />
            </div>
            <div className={styles.mobileTitle}>{product.name}</div>
            <div className={styles.description}>{product.description}</div>
            <div className={styles.promoImg}>
              <Image
                className={styles.promoImg__mobile}
                fluid={usageMobileImage}
              />
              <Image
                className={styles.promoImg__desktop}
                fluid={usageDesktopImage}
              />
            </div>
            <div className={styles.productImages}>
              {previewImages.map((image, idx) => (
                <div key={idx} className={styles.productImageWrap}>
                  <Image fluid={image} className={styles.productImage} />
                </div>
              ))}
            </div>
          </div>
          <div className={styles.productDescriptionWrap}>
            <ProductDescription
              product={product}
              className={styles.productDescription}
            />
          </div>
        </div>
        <div className={styles.categories}>
          {product.categories.map(id => (
            <CategoryProducts categoryId={id} key={id} exclude={[product.id]} />
          ))}
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query ProductImages($previewImagesDir: String!, $imagesDir: String!) {
    previewAllFile: allFile(
      filter: {
        extension: { eq: "png" }
        relativeDirectory: { eq: $previewImagesDir }
      }
    ) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    productAllFile: allFile(
      filter: {
        extension: { eq: "png" }
        relativeDirectory: { eq: $imagesDir }
      }
    ) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default ProductTemplate
